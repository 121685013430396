import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

import { dew as _useKBarDew } from "./useKBar";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });

  var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
    Object.defineProperty(o, "default", {
      enumerable: true,
      value: v
    });
  } : function (o, v) {
    o["default"] = v;
  });

  var __importStar = exports && exports.__importStar || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

    __setModuleDefault(result, mod);

    return result;
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.useRegisterActions = void 0;

  var React = __importStar(_react);

  var useKBar_1 = _useKBarDew();

  function useRegisterActions(actions, dependencies) {
    if (dependencies === void 0) {
      dependencies = [];
    }

    var query = (0, useKBar_1.useKBar)().query; // eslint-disable-next-line react-hooks/exhaustive-deps

    var actionsCache = React.useMemo(function () {
      return actions;
    }, dependencies);
    React.useEffect(function () {
      if (!actionsCache.length) {
        return;
      }

      var unregister = query.registerActions(actionsCache);
      return function () {
        unregister();
      };
    }, [query, actionsCache]);
  }

  exports.useRegisterActions = useRegisterActions;
  return exports;
}